export const GET_MEN_Success = "get/MEN_success"
export const GET_MEN_Error = "get/MEN_error"
export const GET_MEN_Loading = "get/MEN_loading"
export const GET_TotalItem = "get/TotalItem"

export const GET_Page = "get/page"
export const GET_Sort = "get/sort"
export const GET_Category = "get/category"
export const GET_Brand = "get/brand"

export const GET_PageQuery = "get/pagequery"
